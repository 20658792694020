<script>
  import Modal from "./Modal.svelte"
  import NewsletterForm from "./forms/NewsletterForm.svelte"

  let showModal = false
  const inDevelopment = import.meta.env.DEV
  const openModal = () => {
    if (!inDevelopment) {
      setTimeout(() => {
        showModal = true
      }, 1000)
    }
  }
</script>

<svelte:window on:load={openModal} />
<Modal bind:showModal>
  <!-- svelte-ignore illegal-attribute-character -->
  <NewsletterForm client:load />
</Modal>