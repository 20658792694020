<script>
  export let showModal // boolean

  let dialog // HTMLDialogElement

  $: if (dialog && showModal) dialog.showModal()
</script>

<!-- svelte-ignore a11y-click-events-have-key-events a11y-no-noninteractive-element-interactions -->
<dialog class="p-0 border-none max-w-4xl" bind:this={dialog} on:close={() => (showModal = false)} on:click|self={() => dialog.close()}>
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <div class="bg-black text-white p-10 lg:p-20 relative" on:click|stopPropagation>
    <slot />
    <!-- svelte-ignore a11y-autofocus -->
    <button
      aria-label="Close Modal"
      id="menuCloseButton"
      class="pointer-events-auto absolute right-4 top-4 cursor-pointer rounded-full bg-black p-2 text-green hover:animate-throb focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
      autofocus
      on:click={() => dialog.close()}
    >
      <svg class="size-4 lg:size-6" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.68421 12L0 10.3158L4.33083 5.98496L0 1.68421L1.68421 0L6.01504 4.33083L10.3158 0L12 1.68421L7.66917 5.98496L12 10.3158L10.3158 12L6.01504 7.66917L1.68421 12Z"
          fill="currentColor"
        ></path>
      </svg>
    </button>
  </div>
</dialog>

<style>
  dialog::backdrop {
    background: rgba(0, 0, 0, 0.5);
  }
  dialog[open] {
    animation: zoom 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  }
  @keyframes zoom {
    from {
      transform: scale(0.95);
    }
    to {
      transform: scale(1);
    }
  }
  dialog[open]::backdrop {
    animation: fade 0.2s ease-out;
  }
  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
</style>
